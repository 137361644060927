import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { Aside } from "../../../components/widgets/aside";
import { Border } from "../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../components/widgets/button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{`How To Choose a Portfolio`}</h1>
    <Breadcrumbs text="Help" to="/help/" mdxType="Breadcrumbs" />
    <h2>{`Overview`}</h2>
    <Aside mdxType="Aside">
      <p><strong parentName="p">{`See also`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/tactical-asset-allocation/"
          }}>{`The benefits of tactial asset allocation`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/investment-dashboard/"
          }}>{`How to manage your investments`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/account-rebalancing/"
          }}>{`How to rebalance your account`}</a></li>
      </ul>
    </Aside>
    <p>{`Making the proper investment choices can seem overwhelming, especially for the non-professional investor who does not have the time nor inclination to check on the performance of the stock market more than once or twice a month. `}</p>
    <p>{`Many investors want a simple solution: A portfolio that is easy to manage, yet gives them the confidence to reach their investment goals in due time. But, unfortunately, there is no one-size-fits-all solution. Instead, choosing the right portfolio depends on three main factors: the investors appetite for risk, the time horizon, and tax considerations. `}</p>
    <p>{`To address these three investing objectives, TuringTrader tracks more than three dozen portfolios. We have found that almost all investors can find the perfect portfolio for themselves among these choices. We have also found that while variety can be helpful, too many portfolios can quickly become overwhelming and lead to the `}<a parentName="p" {...{
        "href": "https://youtu.be/VO6XEQIsCoM"
      }}>{`paradox of choice`}</a>{`.`}</p>
    <p>{`Our portfolio wizard simplifies this process by packing everything our professional analysts know about investing into one simple tool. After answering four simple questions, the wizard will show you five superb portfolios designed to match your specific investing criteria. `}</p>
    <div css={`display:flex;align-items:center;column-gap:1em;`}>
      <div css={`width:100%;`}>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "711px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/0158b4254454bd379531fd3f3f781ee2/a8e5b/wizard-1.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "38.666666666666664%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAA7CAAAOwgEVKEqAAAABcElEQVQoz4WOTY7TQBBGfUZuwFnYzpIrsGcDQuwYCVZISAyQMJk4ie3Ycf+57dhx3G3noXRgyIgFi6f3Vamqu6I3H2Le3sa8+7jm/ac1t59Tvi0Ed/eC7wsZ8o8HwWwpmceSnyvFYq14WCuWG02caFaZYZVqvs5LIlHu2G5z8rygyAukENSVRssSayRD3zIc9gz9Hte3j/jjU/Adm1QR1U1Ds29ouzbY1jVCG6Q2VLZmcJ7j4C449zf/pj9zdPhxJMlronhbo+sjdj9gW4c0LUpXYWA6TTjvHxmcu+Q/vuqPo2eztURSadK8JMsFWSHIdxJlKoTUlEJRSoXSJvS0qTjPG2uDQ60NurI4N5DklkgbQ1lqRFg8PyJDLkrBNt+Fhe5woO0OdIf+ia9x3l0ufH7/jJvNC0x1JBNNINnVFKrFj57TaWSarjj963HywERa1EQ3X17y6u41i5VhFivmsWK2vHidVeHX/1ORFJb5UvELTLJaHGJBxuAAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "wizard 1",
                "title": "wizard 1",
                "src": "/static/0158b4254454bd379531fd3f3f781ee2/a8e5b/wizard-1.png",
                "srcSet": ["/static/0158b4254454bd379531fd3f3f781ee2/5a46d/wizard-1.png 300w", "/static/0158b4254454bd379531fd3f3f781ee2/0a47e/wizard-1.png 600w", "/static/0158b4254454bd379531fd3f3f781ee2/a8e5b/wizard-1.png 711w"],
                "sizes": "(max-width: 711px) 100vw, 711px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div><div css={`width:100%;`}>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "709px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/f1ace6461d7a310f39015fc6fb1f7c3e/4d08a/wizard-2.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "38.33333333333333%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABb0lEQVQoz42SSY7UQBBFfUVuwG1YwY4z9B4JoV4yLRELGppqqu0aPORoZ9ouymM95KwCGsGCxdPPH5ERGSFl9PJ1zKs3MdfvN1y/S3j7IeXzWnPzTfNlrbm9N3yNFzSrRHO3May3hvutId5Zkr1lk5Zss5JPK0kkhSDPCkQhKPIcJSWu1FgtcVZTmrP2x4bh2AbG7m8YD6SZJfp+POBqj29qmrbBeYcyFls5SucxlaPyNV0//MHxIV3POI2koibybU/VnHHtgC5btC3p+p75NDPNZ5aCwDj+Pl/8MI6cThOp8ES50KS5IisUu0xQSIPUFm1KCqkRSiO1CVNLZVDahtjiA5ec85594YiMsRhToUOBDhcW/dkwFwqhTGiSFTLoEjvnZHikbg90Xce+8ESP7x7xfP+UqhpIVU2mGnaiRpg2rLSsMp+mXzr/w0/zBMzkqiZ68vEZVzcvWG/c5XsYbmPDKjFsM8cu/z+W6VaJ5QcYKFjX14ot8AAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "wizard 2",
                "title": "wizard 2",
                "src": "/static/f1ace6461d7a310f39015fc6fb1f7c3e/4d08a/wizard-2.png",
                "srcSet": ["/static/f1ace6461d7a310f39015fc6fb1f7c3e/5a46d/wizard-2.png 300w", "/static/f1ace6461d7a310f39015fc6fb1f7c3e/0a47e/wizard-2.png 600w", "/static/f1ace6461d7a310f39015fc6fb1f7c3e/4d08a/wizard-2.png 709w"],
                "sizes": "(max-width: 709px) 100vw, 709px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>
    <div css={`display:flex;align-items:center;column-gap:1em;`}>
      <div css={`width:100%;`}>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "710px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/5fd64a1d9dd61a3f3d0e29f20e8c2dab/7131f/wizard-3.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "38.33333333333333%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABeUlEQVQoz42QSW7UQBhGfUVuwG1YwY4zsEdCKEumJUKCBNJJxz15qLnsdnlq23mR3RIkYpPF0/uqSvqHij58ivn4Oebi25aLrxu+fE+4ulX8XEkubyVXa8XvO8V1rFltNDdbw3pnuNsZ4r1lc7BsE8cudfxaSSKR5+SZQOSCNM2QQlCVjlMbznRnhq5eWHI7n5/CUJOklijUgWNVUdWBKlRY77G+INQNVd0sDk275Jm27+lOJ7r+H23XM4wDiTwSmaKlqHr8sVusXcA4j9IGYx3a2MXn7Oj6ntMw/Mc0jSSiJMql5pBKdklOJjRprjHWL0XnSWdLbXBFiS9KtHVY5//a+WJ5nzdN5wmtcRjtEVKhjUEohdQaoTRzszSXZEItBUMzr11TN+35K+qGer4LNX3fnSd8efOCt4fXeH/ioEpSVbEXJcIExnHg/n5cmB4zPfI0Mk4jMJGpI9GrH294d/me9bbgOjYLf2LDamPYpQX77Hkc8pLVxvIA0vVZpowPSGsAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "wizard 3",
                "title": "wizard 3",
                "src": "/static/5fd64a1d9dd61a3f3d0e29f20e8c2dab/7131f/wizard-3.png",
                "srcSet": ["/static/5fd64a1d9dd61a3f3d0e29f20e8c2dab/5a46d/wizard-3.png 300w", "/static/5fd64a1d9dd61a3f3d0e29f20e8c2dab/0a47e/wizard-3.png 600w", "/static/5fd64a1d9dd61a3f3d0e29f20e8c2dab/7131f/wizard-3.png 710w"],
                "sizes": "(max-width: 710px) 100vw, 710px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div><div css={`width:100%;`}>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "711px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/7601121a50cb9466672ac476caf1fb29/a8e5b/wizard-4.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "46%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABq0lEQVQoz4WQy47TQBBF/aOIv+AD+BI27FgigYgQCzQgJMQGBMyQlxO7391+tO3EsTNnFDsgNojFUZ3qlqpvdfLuU8qr92ve3GxYfNyy+LDh8zfBj5Wd+Lm23G0cv7Yzy9Sx2jk2O8d270kzzy4PZLLg660iWW8lwRmM0ThrsEZTBEtVuKkW3lKXntJb+i5yPh2udAx9y9i3DMcWxg6hAsk294QiYHzAOI+9VheKybX10531BVVsJoqqpm5ajqcTh35mGAdyE0nS3KO1YrfPkUojtCYXEqEUUioyIclyiTYGoTRlVeOLgjpGTsNAfzpNnO9HhKlJ9rKkqku0dbgQsOGS0GG9x08pPcpYnJv77nD4Q2wamq4jNi1t15LrimS10yiryJWcEmpr56RKIaREXhJLhTaWXMrJMyGuPnPZoqzKeWC2r+j9QGEi4q+hl9djE6nizKWv6kjdNNTTX85nv30ch3nlt9sbnq6f8Fq+xNsDqXQIU6JcpGqOnM8j45V/+TiOcD8ibSR58X3B4y+PeHb7nFwcWGaB5b7gLvWkssSG5r+Y0OCrluUu8ACDRqKs1qA+GgAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "wizard 4",
                "title": "wizard 4",
                "src": "/static/7601121a50cb9466672ac476caf1fb29/a8e5b/wizard-4.png",
                "srcSet": ["/static/7601121a50cb9466672ac476caf1fb29/5a46d/wizard-4.png 300w", "/static/7601121a50cb9466672ac476caf1fb29/0a47e/wizard-4.png 600w", "/static/7601121a50cb9466672ac476caf1fb29/a8e5b/wizard-4.png 711w"],
                "sizes": "(max-width: 711px) 100vw, 711px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>
    <h2>{`TuringTrader’s Portfolio Wizard`}</h2>
    <p>{`The first question we ask is the `}<strong parentName="p">{`account size`}</strong>{`. Some portfolios invest in several assets each with their own required minimum investment or have other position sizing requirements. Typically, these investment choices will require a larger initial investment than a portfolio consisting of a small number of ETFs.`}</p>
    <p>{`The next question is your `}<strong parentName="p">{`time horizon`}</strong>{`. Aggressive portfolios may achieve higher returns, but also have significantly higher volatility and will require a longer time horizon to recover from market corrections or other events that may result in investment losses. `}</p>
    <p>{`Next we will ask your personal `}<strong parentName="p">{`risk tolerance`}</strong>{`. While there is no right or wrong answer, it is important to choose a portfolio that is aligned with your tolerance for risk. One truth that any experienced investor knows, as well as psychologists, is that losing money feels worse than gaining the same amount of money feels good. In other words, losses hurt more than an equal gain makes you happy. `}</p>
    <p>{`There is nothing worse than starting aggressively, only to de-risk your holdings after experiencing painful losses. We use  `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Monte_Carlo_method"
      }}>{`Monte Carlo simulations`}</a>{` to estimate the paper losses for our portfolios, however, investors must understand that there are no guarantees that portfolios will stay above this value.`}</p>
    <p>{`Finally we will ask the `}<strong parentName="p">{`tax status`}</strong>{` of the investment. Some of our portfolios use hedging techniques that can make the portfolios much more tax efficient, but are unnecessary in tax deferred retirement accounts. Knowing the correct tax status of your investments will make sure the wizard finds the most suitable investments. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "980px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ff54526248d853beeb33bce2b5a17152/d30ee/wizard-result.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACLElEQVQoz1WS2W7bOhiE/f5PdNCbAt2Sk91J4y2yditaKFILRVJ2ga8wnfbgXAw4/4LhYMjFp6uQr/cR3x4SfjylXC8zrpcpV8uE62XCzUvK47pgE0u2sWSXSt4yxT5XhIeOqOiIi468GljtGxZXzwceVwm3LxHf7zbcLAPCXJK896Rl7xcP9cC7GKlaTS01Qk20/YQaDN1o6AeDtY74IC+C/z4FfLvb8uXmlX8+33rh523G0yrheZOS1wOl0B+CE6IztL1BDZZusPSjxdjZG1lcLXOWm4zHdcb9S8SPu7V3vAoKtlFJlAuSQpK+S7JScagURd1Rip6q6aibjqpR9KMmPigWX+9j7+T+NebhNeJ2GfDwM+ThZ8TTOmcVlKz3JZt9zi48EMQFuzAjyUqatqMWiqqRDKMmKRSL65eCXSJYhQ2bqGEbC4JMEmTK4y2VPsOqHanliFDnDEdkpxknhzYObWeOp9Mlw+ddxTps/ofVHx79d8lb0vKWXhCkLfvscgbZhZ/zC7OWxdmq1ppfR8fsJubZcJwtSvU0omMYRo7OINoOIS5w1jBN2md33pGy973TaWah+pGsEORFSyFqJjvgrEWIBj2OnltrqOv6ozbMzjIMPX3f4dxl7vvzWXCY/AuWXctgJ+w8445HhJRoY5iPJ9+rGvFRH31v1BOD1p67+ehxni2G0fgvIMcB7SbcWXCe6fqeyRjPrXO0SmGs/TvX08Q4/dl3fucs+BvlFjO6AIFHyQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "wizard result",
            "title": "wizard result",
            "src": "/static/ff54526248d853beeb33bce2b5a17152/d30ee/wizard-result.png",
            "srcSet": ["/static/ff54526248d853beeb33bce2b5a17152/5a46d/wizard-result.png 300w", "/static/ff54526248d853beeb33bce2b5a17152/0a47e/wizard-result.png 600w", "/static/ff54526248d853beeb33bce2b5a17152/d30ee/wizard-result.png 980w"],
            "sizes": "(max-width: 980px) 100vw, 980px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`After finishing the wizard, we will show the five best portfolios, sorted by a pessimistic estimate for their return. We estimate this value based on a Monte Carlo simulation of the portfolio's historical returns at the 5th percentile. For sufficiently long investment periods, this value will approach the backtested average return, but for shorter investment periods, volatility has a larger impact and reduces the returns you should expect. In consequence, shorter investment periods call for safer investments with lower volatility.`}</p>
    <h2>{`Additional Considerations`}</h2>
    <h3>{`Knowledge & Conviction`}</h3>
    <Aside mdxType="Aside">
      <p><strong parentName="p">{` Learn more`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/charts-metrics/"
          }}>{`Our charts and metrics`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/articles/portfolio-wizard/"
          }}>{`How our portfolio wizard works`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/articles/all-stars-portfolios/"
          }}>{`The benefits of our All-Stars portfolios`}</a></li>
      </ul>
    </Aside>
    <p>{`As convenient as the wizard is, there is one additional element investors need to see things through: conviction. Investors can only achieve the required level of confidence by learning more about investing. That's why we encourage investors to learn and understand how our tool works and how we match your investment objectives with the portfolios, as well as have an understanding of what you are choosing to invest in. In particular, investors should know:`}</p>
    <ul>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "/help/charts-metrics/"
        }}>{`charts and metrics`}</a>{` shown.`}</li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "/articles/backtesting/"
        }}>{`methods of modeling and analysis`}</a>{` used.`}</li>
      <li parentName="ul">{`The mechanics of the portfolio.`}</li>
      <li parentName="ul">{`The portfolio's range of historical outcomes.`}</li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "/articles/portfolio-wizard/"
        }}>{`methodology and assumptions used by our portfolio wizard`}</a></li>
    </ul>
    <p>{`These insights will help you transform your investment objectives into solid portfolio decisions and become an even better investor.`}</p>
    <h3>{`Effort & Lifestyle`}</h3>
    <p>{`Investing with TuringTrader requires discipline. To achieve the results shown on our site, you need to follow the rebalancing schedule as closely as possible. While it might be okay for a portfolio rebalanced monthly to delay rebalancing by a couple of days, portfolios on weekly or daily schedules are typically much less forgiving.`}</p>
    <p>{`Therefore, it is crucial to choose a portfolio that fits your lifestyle. TuringTrader offers portfolios for every lifestyle, and the effort required for portfolio maintenance ranges from five minutes a month up to fifteen minutes a day. All of our portfolios are rebalanced while the markets are closed, so there is no rush, but you still need to make the time.`}</p>
    <p>{`If you are not sure if you can make the time, go with a portfolio that only requires monthly rebalancing. That way, you have a little more wiggle room to fit portfolio maintenance into your busy life.`}</p>
    <ButtonSecondary text="explore our monthly portfolios" to="/portfolios/?tag=monthly#filter" mdxType="ButtonSecondary" />
    <h3>{`Diversification & All-Stars Portfolios`}</h3>
    <p>{`Each portfolio on TuringTrader.com aims to improve risk adjusted returns through diversification and rotating assets. However, we don't know what the future brings and how well the portfolios will deal with unforeseen circumstances. In addition to diversification across asset classes, it is also possible to diversify across investment styles by investing in multiple portfolios simultaneously.`}</p>
    <p>{`This is where our `}<a parentName="p" {...{
        "href": "/articles/all-stars-portfolios/"
      }}>{`family of All-Stars Portfolios`}</a>{` comes in. Each of them combines several of our `}<em parentName="p">{`Premium`}</em>{` portfolios into a meta portfolio with better returns at lower risk, and because each of the component portfolios relies on a different mechanism, our `}<em parentName="p">{`All-Stars Portfolios`}</em>{` can successfully cover a broader range of market situations.`}</p>
    <ButtonSecondary text="explore our all-stars portfolios" to="/portfolios/?tag=meta#filter" mdxType="ButtonSecondary" />
    <h2>{`Conclusion`}</h2>
    <p>{`Making the right investment choice is a crucial part of meeting your financial objectives and can seem overwhelming to even the most experienced investors. We are confident that with the limited information we ask for, our portfolios will offer even the most novice investor a portfolio that is easy to understand and will, given the proper time horizon, meet all of the investment objectives in a stress free manner. `}</p>
    <ButtonPrimary text="explore our portfolios" to="/portfolios/" mdxType="ButtonPrimary" />
    <br /><br /><br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      